<template>
  <v-main
    theme="light"
    class="c-login"
  >
    <v-container
      fluid
      class="fill-height"
    >
      <v-row
        align-content="center"
        justify="center"
        dense
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
          class="p-0"
        >
          <v-card class="elevation-12">
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model="email"
                  class="my-2"
                  color="primary"
                  variant="underlined"
                  density="compact"
                  prepend-icon="mdi-account"
                  name="email"
                  label="メールアドレス"
                  type="text"
                />
                <v-text-field
                  v-model="password"
                  color="primary"
                  bg-color="white"
                  variant="underlined"
                  density="compact"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="パスワード"
                  type="password"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer>
                <span
                  v-if="message != ''"
                  class="notice login-failure"
                >{{ message }}</span>
              </v-spacer>
              <v-btn
                color="pink"
                variant="elevated"
                class="text-black"
                @click="onLogin()"
              >
                ログイン
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  emits: ["setUpNotice", "setUpLogoutTimer"],
  data: () => ({
    email: "",
    password: "",
    redirectPath: "",
    message: ""
  }),
  computed: {
    ...mapGetters(["loggedIn"]),
  },
  methods: {
    ...mapActions(["login"]),
    onLogin: function() {
      this.login({ email: this.email,
        password: this.password,
        router: this.$router,
        redirectPath: this.redirectPath }
      ).then(() => {
        if(this.loggedIn == false){
          this.message = "ログインに失敗しました";
        } else {
          this.$emit("setUpNotice");
          this.$emit("setUpLogoutTimer");
        }
      });
    },
  },
  created() {
    this.redirectPath = this.$route.query.redirect_to;
  }
};
</script>

<style lang="scss">
.c-login {
  .notice {
    color: rgb(233, 30, 99);
    font-size: .875rem;
  }
  .login-failure {
    padding-left: 12px;
  }

  .v-field__input{
    padding: 8px 0;
  }

  .v-input--horizontal .v-input__prepend {
    margin-inline-end: 9px;
  }

  .v-btn {
    margin: 6px 8px;
    padding: 0 8px;
  }
  .btn-label .v-btn__content {
    font-size: 13px;
  }
}
</style>
