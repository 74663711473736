import { createStore } from "vuex";
import children from "./modules/children";
import kartes from "./modules/kartes";
import reservations from "./modules/reservations";
import shifts from "./modules/shifts";
import users from "./modules/users";
import doctors from "./modules/doctors";
import sessions from "./modules/sessions";
import anytimeConsultations from "./modules/anytime_consultations";
import recommendJournals from "./modules/recommend_journals";
import epdsAnswers from "./modules/epds_answers";
import chatLogs from "./modules/chat_logs";
import rooms from "./modules/rooms";
import userKartes from "./modules/users/kartes";
import userAnytimeConsultations from "./modules/users/anytime_consultations";
import daytimeAdvices from "./modules/daytime_advices";
import anytimeConsultationShifts from "./modules/anytime_consultation_shifts";
import anytimeConsultationShift from "./modules/anytime_consultation_shift";
import search from "./modules/search";

//const debug = process.env.NODE_ENV !== 'production'
const debug = false;

export default createStore({
  modules: {
    children,
    kartes,
    reservations,
    shifts,
    users,
    doctors,
    sessions,
    anytimeConsultations,
    recommendJournals,
    epdsAnswers,
    chatLogs,
    rooms,
    userKartes,
    userAnytimeConsultations,
    daytimeAdvices,
    anytimeConsultationShifts,
    anytimeConsultationShift,
    search
  },
  strict: debug
});
