import axios from "./index";


export default {
  login(callback, params) {
    axios.post("/api/v1/doctors/session", {
      doctor: {
        email: params.email,
        password: params.password,
        remember_me: 0
      }
    }
    ).then(() => {
      callback(true);
    }).catch(error => {
      console.log(error);
      callback(false);
    });
  },

  logout(callback) {
    axios.delete("/api/v1/doctors/session").then(() => {
      callback(false);
    }).catch(error => {
      console.log(error);
    });
  },

  checkLoggedIn(callback) {
    axios.get("/api/v1/doctors/logged_in").then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  
  fetchCurrentDoctor(callback) {
    axios.get("/api/v1/doctors/current_session").then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  }
};
