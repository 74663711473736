import { createRouter, createWebHistory } from "vue-router";

import Tab from "../components/Tab.vue";
import ReservationList from "../components/ReservationList.vue";
import Karte from "../components/Karte.vue";
import Shift from "../components/Shift.vue";
import ShiftEdit from "../components/ShiftEdit.vue";
import Login from "../components/Login.vue";
import KarteView from "../components/KarteView.vue";
import AnytimeConsultationHome from "../components/AnytimeConsultationHome.vue";
import AnytimeConsultationList from "../components/AnytimeConsultationList.vue";
import AnytimeConsultationSearch from "../components/AnytimeConsultationSearch.vue";
import AnytimeConsultation from "../components/AnytimeConsultation.vue";
import AnytimeConsultationView from "../components/AnytimeConsultationView.vue";
import AnytimeConsultationShift from "../components/AnytimeConsultationShift.vue";
import AnytimeConsultationShiftEdit from "../components/AnytimeConsultationShiftEdit.vue";
import EpdsView from "../components/EpdsView.vue";
import DaytimeAdviceHome from "../components/DaytimeAdviceHome.vue";
import DaytimeAdviceRoom from "../components/DaytimeAdviceRoom.vue";
import DaytimeAdviceSearch from "../components/DaytimeAdviceSearch.vue";
import DaytimeAdviceMonitor from "../components/DaytimeAdviceMonitor.vue";
import DaytimeAdviceSheet from "../components/DaytimeAdviceSheet.vue";
import DaytimeAdviceSheetView from "../components/DaytimeAdviceSheetView.vue";
import DaytimeAdviceSearchView from "../components/DaytimeAdviceSearchView.vue";

import axios from "../api/index";

import store from "../stores/index";

let router = createRouter({
  history: createWebHistory(),
  routes: [{
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/reservations",
    component: Tab,
    meta: {
      requiresAuth: true,
      nightConsultationAvailable: true
    },
    children: [
      {
        path: "list",
        name: "list",
        component: ReservationList
      },
      {
        path: "karte",
        name: "karte",
        component: Karte
      },
    ]
  },
  // サブウィンドウによる表示
  {
    path: "/reservations/:id",
    name: "karteView",
    component: KarteView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/shifts",
    name: "shift",
    component: Shift,
    meta: {
      requiresAuth: true,
      requiresOperator: true
    }
  },
  {
    path: "/shifts/edit",
    name: "shiftEdit",
    component: ShiftEdit,
    meta: {
      requiresAuth: true,
      requiresOperator: true
    }
  },
  {
    path: "/anytime_consultations",
    component: AnytimeConsultationHome,
    meta: {
      requiresAuth: true,
      anytimeConsultationAvailable: true
    },
    children: [{
      path: "",
      name: "anytimeConsultationList",
      component: AnytimeConsultationList
    },
    {
      path: "/anytime_consultations/search/",
      name: "anytimeConsultationSearch",
      component: AnytimeConsultationSearch
    }]
  },
  {
    path: "/anytime_consultations/:id",
    name: "anytimeConsultation",
    component: AnytimeConsultation,
    meta: {
      requiresAuth: true,
      anytimeConsultationAvailable: true
    }
  },
  // サブウィンドウによる表示
  {
    path: "/anytime_consultations/view/:id",
    name: "anytimeConsultationView",
    component: AnytimeConsultationView,
    meta: {
      requiresAuth: true
    }
  },
  // サブウィンドウによる表示
  {
    path: "/epds/:id",
    name: "EpdsView",
    component: EpdsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/daytime_advices",
    component: DaytimeAdviceHome,
    meta: {
      requiresAuth: true,
      daytimeAdviceAvailable: true
    },
    children: [{
      path: "room",
      name: "daytimeAdviceRoom",
      component: DaytimeAdviceRoom,

      children: [{
        path: "sheet/:id",
        name: "daytimeAdviceSheet",
        component: DaytimeAdviceSheet
      }]
    },
    {
      path: "search",
      name: "daytimeAdviceSearch",
      component: DaytimeAdviceSearch
    },
    {
      path: "search/:id",
      name: "daytimeAdviceSearchView",
      component: DaytimeAdviceSearchView
    },
    {
      path: "monitor",
      name: "daytimeAdviceMonitor",
      component: DaytimeAdviceMonitor
    }]
  },
  // サブウィンドウによる表示
  {
    path: "/daytime_advices_sheets/:id",
    name: "daytimeAdviceSheetView",
    component: DaytimeAdviceSheetView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/anytime_consultation_shifts",
    name: "ac_shift",
    component: AnytimeConsultationShift,
    meta: {
      requiresAuth: true,
      requiresOperator: true
    }
  },
  {
    path: "/anytime_consultation_shifts/edit",
    name: "ac_shift_edit",
    component: AnytimeConsultationShiftEdit,
    meta: {
      requiresAuth: true,
      requiresOperator: true
    }
  },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.dispatch("countClear");
    store.dispatch("clearTimeoutApproaching");
    axios.get("/api/v1/doctors/current_session").then(response => {
      if (response.status == 200 && response.data.id > 0) {
        let current_doctor = response.data;
        try {
          // Operatorのみ
          if (to.matched.some(record => record.meta.requiresOperator)) {
            if( current_doctor.type != "Operator::Doctor" ) throw new AuthorityError();
          }
          // 夜間相談が利用できるユーザーのみ
          if (to.matched.some(record => record.meta.nightConsultationAvailable)) {
            if( !current_doctor.nightConsultationAvailable ) throw new AuthorityError();
          }
          // いつでも相談が利用できるユーザーのみ
          if (to.matched.some(record => record.meta.anytimeConsultationAvailable)) {
            if( !current_doctor.anytimeConsultationAvailable ) throw new AuthorityError();
          }
          // 日中相談が利用できるユーザーのみ
          if (to.matched.some(record => record.meta.daytimeAdviceAvailable)) {
            if( !current_doctor.daytimeAdviceAvailable ) throw new AuthorityError();
          }
        } catch(e) {
          if( e instanceof AuthorityError ) {
            console.log("このページにアクセスする権限がありません。");
          } else {
            console.log(e.message);
          }
          next({ path: current_doctor.homeUrl, replace: true });
        }

        next();

      } else {
        // 自動リロード処理が実行されている場合停止する
        clearTimeout(store.getters.reloadTimeoutId);
        next({
          path: "/login",
          query: {
            redirect: to.fullPath
          }
        });
      }
    }).catch(() => {
      // 自動リロード処理が実行されている場合停止する
      clearTimeout(store.getters.reloadTimeoutId);
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    });
  } else {
    store.dispatch("countClear");
    store.dispatch("clearTimeoutApproaching");
    next();
  }
});

class AuthorityError extends Error{
  constructor() {
    super();
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export default router;
